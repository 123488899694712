import React from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { getActiveTheme } from 'utils/theme'

// import Avatar from 'components/Avatar'
// import Menu from 'components/Menu'
// import ToggleTheme from 'components/ToggleTheme'
import {
  LessThanSmallMobile,
  GreaterThanSmallMobile,
} from 'components/Responsive'

import { Header as HeaderStyled, Main, Brand, Menu, Title } from './styles'
import Wrapper from 'components/Wrapper'

const Header = ({ site: { author } }) => {
  return (
    <HeaderStyled>
      <Wrapper>
        <Main>
          <AniLink
            cover
            bg={getActiveTheme()}
            direction='down'
            duration={1}
            to='/'
            title='Voltar para home'
          >
            <Brand>
              {/* <Avatar /> */}
              <LessThanSmallMobile>
                <Title>Allison</Title>
              </LessThanSmallMobile>
              <GreaterThanSmallMobile>
                <Title>{author}</Title>
              </GreaterThanSmallMobile>
            </Brand>
          </AniLink>
          {/* <Menu>
            <Menu />
            <ToggleTheme />
          </Menu> */}
        </Main>
      </Wrapper>
    </HeaderStyled>
  )
}

Header.propTypes = {
  site: PropTypes.shape({
    author: PropTypes.string.isRequired,
  }),
}

export default Header
