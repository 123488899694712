import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { TransitionPortal } from 'gatsby-plugin-transition-link'

import GlobalStyles from 'components/GlobalStyles'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Wrapper from 'components/Wrapper'

import { Container as ContainerStyled, Main } from './styles'

const Container = ({ children }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            position
            description
          }
        }
      }
    `
  )

  return (
    <ContainerStyled>
      <GlobalStyles />
      <TransitionPortal level='top'>
        <Header site={site.siteMetadata} />
      </TransitionPortal>
      <Main>
        <Wrapper>{children}</Wrapper>
      </Main>
      <TransitionPortal level='bottom'>
        <Footer />
      </TransitionPortal>
    </ContainerStyled>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
