import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ReactGA from 'react-ga'

import {
  Social as SocialStyled,
  SocialGithub,
  SocialItem,
  SocialLinkedin,
  SocialTwitter,
  SocialBlogger,
  LinkIcon
} from './styles'

const trackClick = item => {
  ReactGA.event({
    category: 'Social',
    action: 'click',
    label: `Social - ${item}`,
  })
}

const Social = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              twitterLink
              linkedinLink
              githubLink
            }
          }
        }
      }
    `
  )

  return (
    <SocialStyled>
      <SocialItem
        href={'/blog'}
        title='Blog'
        onClick={() => trackClick('Blog')}
        target="_self"
        referrer
      >
        <SocialBlogger />
      </SocialItem>
      <SocialItem
        href={site.siteMetadata.social.twitterLink}
        title='Twitter'
        onClick={() => trackClick('Twitter')}
      >
        <SocialTwitter />
      </SocialItem>
      <SocialItem
        href={site.siteMetadata.social.githubLink}
        title='Github'
        onClick={() => trackClick('Github')}
      >
        <SocialGithub />
      </SocialItem>
      <SocialItem
        href={site.siteMetadata.social.linkedinLink}
        title='LinkedIn'
        onClick={() => trackClick('LinkedIn')}
      >
        <SocialLinkedin />
      </SocialItem>
      <SocialItem
        href='/links'
        target="_self"
        title='My links'
        onClick={() => trackClick('Links')}
      >
        <LinkIcon />
      </SocialItem>
    </SocialStyled>
  )
}

export default Social
