import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper as WrapperStyled } from './styles'

const Wrapper = ({ children }) => {
  return <WrapperStyled>{children}</WrapperStyled>
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Wrapper
