import styled from 'styled-components'
import media from 'styled-media-query'

import { ZIndex, Color, Height, Space } from 'utils/theme'

export const Header = styled.header.attrs({
  role: 'banner',
})`
  background-color: ${Color.black};
  color: ${Color.white};
  position: fixed;
  top: 0;
  width: 100%;
`

export const Main = styled.div`
  align-items: center;
  display: flex;
  height: ${Height.headerSm};
  justify-content: center;

  ${media.greaterThan('medium')`
    height: ${Height.headerLg};
  `}
`

export const Brand = styled.div`
  align-items: center;
  display: flex;

  > *:not(:last-child) {
    margin-right: ${Space.sm};
  }
`

export const Menu = styled.div`
  align-items: center;
  display: flex;

  ${media.lessThan('small')`
    margin-right: -${Space.xs};
  `}

  > *:not(:last-child) {
    margin-right: ${Space.xs};
  }
`

export const Title = styled.h1`
  color: ${Color.white};
  display: inline-block;
  font-size: 1.7rem;
  letter-spacing: 0.025rem;

  ${media.lessThan('350px')`
    display: none;
  `}

  ${media.greaterThan('small')`
    font-size: 2.1rem;
  `}
`
