import styled from 'styled-components'
import media from 'styled-media-query'

import { Height, Space } from '@/utils/theme'

export const Container = styled.section`
  background-color: var(--bg);
  display: block;
  transition: background-color var(--transition);
  will-change: background-color;
`

export const Main = styled.main.attrs({
  role: 'main',
})`
  margin-bottom: ${Height.footer};
  margin-top: ${Height.headerSm};
  min-height: calc(100vh - 109px);
  padding-bottom: ${Space.xxlg};
  padding-top: ${Height.mainTop};
  width: 100%;

  ${media.greaterThan('medium')`
    margin-top: ${Height.headerLg};
  `}
`
