import React from 'react'

import Social from 'components/Social'

import { Footer as FooterStyled } from './styles'

const Footer = () => {
  return (
    <FooterStyled>
      <Social />
    </FooterStyled>
  )
}

export default Footer
