import styled from 'styled-components'
import media from 'styled-media-query'

import { MaxSize, Space } from 'utils/theme'

export const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${MaxSize.lg};
  padding-left: ${Space.sm};
  padding-right: ${Space.sm};

  ${media.greaterThan('medium')`
    padding-left: ${Space.default};
    padding-right: ${Space.default};
  `}
`
