import { css } from 'styled-components'
import media from 'styled-media-query'

export const getActiveTheme = () => {
  if (typeof document === 'undefined') return

  const body = document.querySelector('body')
  return body.classList.contains('theme-dark') ? '#000' : '#fff'
}

export const Space = {
  xxs: `.4rem`,
  xs: `.8rem`,
  sm: `1.6rem`,
  default: `2.4rem`,
  md: `3.2rem`,
  lg: `4.5rem`,
  xlg: `6.4rem`,
  xxlg: `9.6rem`,
}

export const Height = {
  headerSm: `${Space.lg}`,
  headerLg: `${Space.xlg}`,
  footer: `4.5rem`,
  mainTop: `${Space.md}`,
}

export const MaxSize = {
  md: `750px`,
  lg: `1080px`,
}

export const ZIndex = {
  bg: `-1`,
  default: `1`,
  footer: `5`,
  header: `5`,
}

export const Color = {
  white: `rgba(255, 255, 255, .9)`,
  black: `#0f171f`,
  whiteSolid: `#ffffff`,
  blackSolid: `#070b0e`,
  blue: `#187888`,
  blueLight: `#7ccdff`,
  grayDarker: `#15202b`,
  grayDark: `#2d2d2d`,
  gray: `#757575`,
  grayLight: `#d2d2d2`,
  grayLighter: `#efefef`,
  grayWhite: `#f1f1f1`,
  yellow: `#e6af05`,
}


export const BoxShadow = {
  box: `0 1px 3px var(--boxShadow)`,
}

export const Border = {
  box: `1px solid var(--borderColor)`,
  default: `1px solid var(--borderColor)`,
}

export const BorderRadius = {
  box: `5px`,
}

//Typography
export const Title = css`
  & {
    color: var(--primaryColor);
    font-size: 3.4rem;
    line-height: 1.1em;

    ${media.greaterThan('medium')`
      font-size: 4.2rem;
    `}
  }
`

export const Subtitle1 = css`
  & {
    color: var(--secondaryColor);
    font-size: 2.1rem;
    letter-spacing: -0.45px;
    line-height: 1.5em;

    ${media.greaterThan('medium')`
      font-size: 2.4rem;
    `}
  }
`

export const Subtitle2 = css`
  & {
    color: var(--secondaryColor);
    font-size: 2rem;
    letter-spacing: -0.04px;
    line-height: 1.3em;
  }
`

export const Heading1 = css`
  & {
    color: var(--primaryColor);
    font-size: 2.6rem;
    font-weight: 600;
    letter-spacing: -0.022em;
    line-height: 1.3em;

    ${media.greaterThan('medium')`
      font-size: 3rem;
    `}
  }
`

export const Heading2 = css`
  & {
    color: var(--primaryColor);
    font-size: 2.1rem;
    font-weight: 600;
    letter-spacing: -0.04px;
    line-height: 1.5em;

    ${media.greaterThan('medium')`
      font-size: 2.4rem;
    `}
  }
`

export const Heading3 = css`
  & {
    color: var(--primaryColor);
    font-size: 2.1rem;
    font-weight: 400;
    letter-spacing: -0.04px;
    line-height: 1.5em;

    ${media.greaterThan('medium')`
      font-size: 2.4rem;
    `}
  }
`

export const Heading4 = css`
  & {
    color: var(--primaryColor);
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: -0.04px;
    line-height: 1.5em;
  }
`

export const Text1 = css`
  & {
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: -0.04px;
    line-height: 1.5em;
  }
`

export const Text2 = css`
  & {
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: -0.14px;
    line-height: 1.55em;
  }
`

export const LinkTransition = css`
  & {
    position: relative;
    will-change: transform;
    box-sizing: border-box;
  }

  &:after {
    background-color: var(--highlightColor);
    content: '';
    height: 2px;
    left: 0;
    margin-top: ${Space.xxs};
    position: absolute;
    top: 100%;
    transition: width var(--transition) ease-in-out;
    width: 0;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
`

export const Link = css`
  & {
    color: var(--highlightColor);
    ${LinkTransition}
  }
`
