import styled from 'styled-components'

import { Border, Color, Height, Space, ZIndex } from 'utils/theme'

export const Footer = styled.footer.attrs({
  role: 'dialog',
})`
  align-items: center;
  background-color: var(--bgSolid);
  border-top: ${Border.default};
  bottom: 0;
  box-shadow: 2px 3px 4px var(--boxShadow);
  color: ${Color.white};
  display: flex;
  height: ${Height.footer};
  justify-content: center;
  left: 0;
  padding-left: ${Space.default};
  padding-right: ${Space.default};
  position: fixed;
  right: 0;
  z-index: ${ZIndex.footer};
`
