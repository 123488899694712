import styled, { css } from 'styled-components'
import { Twitter, Github, LinkedinIn, Blogger } from '@styled-icons/fa-brands'
import { Links } from '@styled-icons/remix-fill'

import { Height, Space } from '@/utils/theme'

const Icon = css`
  & {
    display: block;
    height: 2rem;
    width: 2rem;
  }
`

export const Social = styled.div`
  display: flex;
`

export const SocialItem = styled.a.attrs(props => ({
  href: props.href,
  title: props.title,
  target: props.target || '_blank',
  rel: props.referrer ? '' : 'noopener noreferrer',
}))`
  align-items: center;
  color: var(--primaryColor);
  display: inline-flex;
  height: ${Height.footer};
  padding-left: ${Space.sm};
  padding-right: ${Space.sm};
  transition: color var(--transition);
  will-change: color;

  &:focus,
  &:hover {
    color: var(--highlightColor);
  }
`

export const SocialTwitter = styled(Twitter)`
  ${Icon}
`

export const SocialGithub = styled(Github)`
  ${Icon}
`

export const SocialLinkedin = styled(LinkedinIn)`
  ${Icon}
`

export const SocialBlogger = styled(Blogger)`
  ${Icon}
`

export const LinkIcon = styled(Links)`
  ${Icon}
`
